<template src="./Members.html"></template>

<script>
import MemberListTable from "./memberListTable/MemberListTable.vue";
import { Button } from "@progress/kendo-vue-buttons";
import MemberImport from "./memberImport/MemberImport";
import MemberImportModal from "./membersImportModal/MemberImportModal";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import modal from "../../common/AppModal/AppModal.vue";
import axios from "axios";
import MemberMappingModal from "../members/memberMappingModal/MemberMappingModal";
import MemberUploadSnackbar from "./memberUploadSnackbar/MemberUploadSnackbar";
import AppLoader from "../../common/AppLoader/AppLoader.vue";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { auth } from "@/services/auth.service";
import { configuration } from "@/configurationProvider";
import AddIndividualMemberModal from "./memberImport/addIndividualMemberModal/AddIndividualMemberModal.vue";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import { Checkbox } from "@progress/kendo-vue-inputs";

export default {
  name: "Members",
  components: {
    MemberListTable: MemberListTable,
    kbutton: Button,
    MemberImport,
    Breadcrumb,
    modal,
    MemberMappingModal,
    MemberUploadSnackbar,
    MemberImportModal,
    AppLoader,
    AppSnackBar,
    AddIndividualMemberModal,
    "k-input": Input,
    dropdownlist: DropDownList,
    Popup,
    checkbox: Checkbox,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      memberFilterPopupVisibility: false,
      itemCode: null,
      moduleCode: "members",
      clubId: this.$route.params.id,
      modalVisible: false,
      importModalVisible: false,
      passList: [],
      openMembermapping: false,
      closeMemberImport: true,
      importedDocId: "",

      limit: 100,
      offset: 0,
    };
  },
  // watch: {
  //   memberList(newVal, oldVal) {
  //     if (newVal.length > 0) {
  //       setTimeout(() => {
  //         if (this.$route.query.scrollY) {
  //           this.$refs.scrollableDiv.scrollTo({
  //             top: this.$route.query.scrollY,
  //             behavior: "smooth",
  //           });
  //         }
  //       }, 1000);
  //     }
  //   },
  // },
  computed: {
    addIndividulMemberModalVisible() {
      return this.$store.state.members.addIndividualMemberModalVisibility;
    },
    memberList() {
      return this.$store.state.members.items;
    },
    modalVisibleForLog() {
      return this.$store.state.members.loader;
    },
    isSearching() {
      return this.$store.state.members.searchQuery;
    },
    anyFilterExistInMembers() {
      return this.$store.state.members.anyFilterExistInMembers;
    },
    memberLoading() {
      return this.$store.state.members.loading;
    },
    addIndividualMemberModalLoader() {
      return this.$store.state.members.addIndividualMemberModalLoader;
    },

    isShowMsg() {
      return this.$store.state.members.showMsg;
    },
    isShowSuccessMsg() {
      return this.$store.state.members.showSuccessMsg;
    },
    isShowErrorMsg() {
      return this.$store.state.members.showErrorMsg;
    },
    showMsg() {
      return this.$store.state.members.status;
    },
    showSuccessMsg() {
      return this.$store.state.members.successStatus;
    },
    showErrorMsg() {
      return this.$store.state.members.errorStatus;
    },

    getClubName() {
      return this.$store.state.club.club.name;
    },
    getClubDetails() {
      return this.$store.state.club.club;
    },
    isLoading() {
      return this.$store.state.common.loader;
    },
    fetchController() {
      return this.$store.state.members.fetchController;
    },
  },
  mounted() {
    // if (this.$route.query.limit && this.$route.query.offset) {
    //   this.limit = this.$route.query.limit;
    //   this.offset = this.$route.query.offset;
    // } else {
    //   this.$router.push({
    //     query: {
    //       limit: this.limit,
    //       offset: this.offset,
    //     },
    //   });
    // }

    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.$store.commit("SET_MEMBERS", []);
    this.$store.commit("SET_ITEMS", []);
    this.getMemberList();
    // this.getClub(this.clubId);
  },
  unmounted() {
    this.$store.commit("SET_SHOW_MESSAGE", false);
    this.$store.commit("SET_MEMBERS", []);
    this.$store.commit("SET_ITEMS", []);
  },
  methods: {
    // handleScroll() {
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.$router.push({
    //       query: {
    //         ...this.$route.query,
    //         scrollY: this.$refs.scrollableDiv.scrollTop,
    //       },
    //     });
    //   }, 300);
    // },
    memberFilterHandler() {
      this.memberFilterPopupVisibility = !this.memberFilterPopupVisibility;
    },
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    getMemberList() {
      let payload = {
        clubId: this.$props.id,
        limit: this.limit,
        offset: this.offset,
      };
      this.$store.dispatch("getMembers", payload);
    },
    openModal() {
      this.modalVisible = true;
    },
    openImportModal() {
      this.importModalVisible = true;
      this.closeMemberImport = true;
    },
    save(data) {
      let clubId = this.$route.params.id;
      let model = {
        documentId: data,
        clubId: clubId,
      };
      this.$store.dispatch("importMember", model);
    },
    async exportList() {
      this.$store.commit("LOADING_STATUS", true);

      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios({
        url: `${configuration.apiBaseUrl}/api/v1/member/${this.$props.id}/members/export`,
        method: "POST",
        responseType: "blob",
        headers: headers,
      })
        .then((response) => {
          this.$store.commit("LOADING_STATUS", false);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "Members exported successfully.";
          this.$store.commit("SET_CONTROL_STATUS", model);
          this.$store.commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_SHOW_MESSAGE", false);
          }, 2000);
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = "Members of " + this.getClubName + ".xlsx";
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          this.$store.commit("SET_LOADER", false);
          var model = new SnackBarModel();
          model.type = "error";
          model.Message = "No Members to export.";
          this.$store.dispatch("showNotification", model);
        });
    },
    modalHandler(data) {
      this.importModalVisible = data;
    },
    getopenMemberMapping(value) {
      this.closeMemberImport = false;
      this.openMembermapping = value.isModalOpen;
      this.importedDocId = value.doccumentId;
    },
    getCloseMemberImportModal(value) {
      this.closeMemberImport = false;
    },
    getopenMemberImport(value) {
      this.closeMemberImport = value;
      this.openMembermapping = false;
    },
    closeAllModals() {
      this.importModalVisible = false;
      this.closeMemberImport = false;
      this.openMembermapping = false;
    },
    getClub(e) {
      this.$store.dispatch("getClub", e);
    },
    openModalForAddMember() {
      this.$store.commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_VISIBILITY", true);
    },
  },
};
</script>

<style></style>

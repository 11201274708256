<modal
  :isLoading="addIndividualMemberModalLoader"
  :class="'max-height-100-145'"
  title="Add Member"
  :handler="true"
  @modalHandler="this.modalHandler"
  :cssClass="'modal-md-3'"
>
  <template v-slot:content>
    <div class="">
      <div class="row">
        <div class="col-md-6 col-12 pr-10 px-15-sm">
          <div class="k-form-field text-start">
            <label>First Name*</label>
            <k-input
              @keyup="firstNameError=''"
              v-model="firstName"
              :placeholder="'Input First Name'"
            />
            <div class="error-msg">{{ firstNameError }}</div>
          </div>
        </div>
        <div class="col-md-6 col-12 pl-10 px-15-sm">
          <div class="k-form-field text-start">
            <label>Last Name*</label>
            <k-input
              @keyup="lastNameError=''"
              v-model="lastName"
              :placeholder="'Input Last Name'"
            />
            <div class="error-msg">{{ lastNameError }}</div>
          </div>
        </div>
        <div class="col-12 px-15-sm">
          <div class="k-form-field text-start">
            <label>Email Address*</label>
            <k-input
              @keyup="clearEmailError"
              v-model="email"
              :placeholder="'user@domain.com'"
            />
            <div class="error-msg">
              {{this.addMemberErrors?.Email?.[0] || emailError}}
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 pr-10 px-15-sm">
          <div class="k-form-field text-start">
            <!-- :default-value="this.defaultValue" -->
            <label>Phone Number</label>
            <!-- <MaskedTextBox :placeholder="defaultValue" :mask="this.mask"></MaskedTextBox> -->
            <k-input v-model="mobile" :placeholder="'+61 XXX XXX XXXX'" />
          </div>
        </div>
        <div class="col-md-6 col-12 pl-10 px-15-sm">
          <div class="k-form-field text-start">
            <label>Gender</label>
            <dropdownlist
              :data-items="this.genderItems"
              :text-field="'text'"
              :data-item-key="'id'"
              :class="'mb-3'"
              :value="this.genderValue"
              @change="this.handleGenderChangeValue"
            >
            </dropdownlist>
          </div>
        </div>
        <div class="col-md-6 col-12 pr-10 px-15-sm">
          <div class="k-form-field text-start">
            <label>Joining Date</label>
            <datePicker
              :format="'dd-MMM-yyyy'"
              v-model="joiningDate"
              :class="'button-bg-transparent button-border-none'"
            >
            </datePicker>
            <!-- <div class="error-msg">
              {{this.addMemberErrors?.Email?.[0] || emailError}}
            </div> -->
          </div>
        </div>
        <!-- {{timeZonesList}} -->
        <div class="col-md-6 col-12 pl-10 px-15-sm">
          <div class="k-form-field text-start">
            <label>Time Zone</label>
            <dropdownlist
              :data-items="timeZonesList"
              :text-field="'name'"
              :data-item-key="'id'"
              :class="'mb-3'"
              :filterable="true"
              @filterchange="filterChange"
              :value="timeZoneValue"
              @change="handleTimeZoneChangeValue"
            >
            </dropdownlist>
          </div>
        </div>
      </div>
      <div class="border-normal-1 radius-8 mb-16">
        <div class="text-start">
          <div
            class="p-12 bg-gray-4 radius-8"
            v-if="payload?.enablePass || payload?.enableVoucher || payload?.enableCampaign"
          >
            <label class="m-0 font-13 font-w-500 text-color-2"
              >Include Imported Members In</label
            >
          </div>
          <div class="px-15">
            <div
              v-if="payload?.enablePass"
              class="row k-input-mb-0 border-top-normal-1 py-16"
            >
              <div class="col-md-6 col-sm-12">
                <checkbox
                  :value="this.digitalPassCheckBoxStatus"
                  @change="this.handleDigitalPassCheckBoxStatus"
                >
                  <label :class="'font-14 font-w-600 mb-0 ms-2'"
                    >Digital Pass</label
                  >
                </checkbox>
                <label
                  class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                  v-if="this.digitalPassCheckBoxStatus"
                  >Assign to Digital Pass list</label
                >
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="this.digitalPassCheckBoxStatus"
              >
                <div class="dropdown-tree-wrap">
                  <dropdowntree
                    @change="digitalPassListonChange"
                    :data-source="this.localDataSourceForDigitalPass"
                    :checkboxes="true"
                    :autoClose="false"
                    :placeholder="'Select List'"
                    style="width: 100%"
                  >
                  </dropdowntree>
                  <span class="dropdown-item-count"
                    >{{digitalPassListIds.length}}</span
                  >
                </div>
              </div>
            </div>
            <div
              v-if="payload?.enableVoucher"
              class="row k-input-mb-0 border-top-normal-1 py-16"
            >
              <div class="col-md-6 col-sm-12">
                <checkbox
                  :value="this.voucherCheckBoxStatus"
                  @change="this.handleVoucherCheckBoxStatus"
                >
                  <label :class="'font-14 font-w-600 mb-0 ms-2'">Voucher</label>
                </checkbox>
                <label
                  class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                  v-if="this.voucherCheckBoxStatus"
                  >Assign to Voucher list</label
                >
              </div>
              <div class="col-md-6 col-sm-12" v-if="this.voucherCheckBoxStatus">
                <div class="dropdown-tree-wrap">
                  <dropdowntree
                    @change="voucherListonChange"
                    :data-source="this.localDataSourceForVoucher"
                    :checkboxes="true"
                    :autoClose="false"
                    :placeholder="'Select List'"
                    style="width: 100%"
                  >
                  </dropdowntree>
                  <span class="dropdown-item-count"
                    >{{voucherCampaignListIds.length}}</span
                  >
                </div>
              </div>
            </div>

            <div
              v-if="payload?.enableCampaign"
              class="row k-input-mb-0 border-top-normal-1 py-16"
            >
              <div class="col-md-6 col-sm-12">
                <checkbox
                  :value="emailCampaignCheckBoxStatus"
                  @change="handleEmailCampaignCheckBoxStatus"
                >
                  <label :class="'font-14 font-w-600 mb-0 ms-2'"
                    >Email Campaign</label
                  >
                </checkbox>
                <label
                  class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                  v-if="emailCampaignCheckBoxStatus"
                  >Assign to Campaign List</label
                >
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="emailCampaignCheckBoxStatus"
              >
                <div class="dropdown-tree-wrap">
                  <dropdowntree
                    @change="emailCampaignListonChange"
                    :data-source="localDataSourceForEmailCampaign"
                    :noDataTemplate="'No lists found.'"
                    :checkboxes="true"
                    :autoClose="false"
                    :placeholder="'Select List'"
                    style="width: 100%"
                  >
                  </dropdowntree>
                  <span class="dropdown-item-count"
                    >{{emailCampaignListIds.length}}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12">
            <div class="k-form-field text-start">
              <div v-if="payload?.enablePass" class="row mb-3 k-input-mb-0">
                <div class="col-sm-12">
                  <checkbox :value="this.digitalPassCheckBoxStatus" @change="this.handleDigitalPassCheckBoxStatus"
                    :label="'Digital Pass'" />
                </div>
                <div class="col-md-6 col-sm-12 mt-20" v-if="this.digitalPassCheckBoxStatus">
                  <label class="mb-0">Assign to Digital Pass List</label>
                </div>
                <div class="col-md-6 col-sm-12 mt-3" v-if="this.digitalPassCheckBoxStatus">
                  <div class="dropdown-tree-wrap">
                    <dropdowntree @change="digitalPassListonChange" :data-source="this.localDataSourceForDigitalPass"
                      :noDataTemplate="'No lists found.'" :checkboxes="true" :autoClose="false"
                      :placeholder="'Select List'" style="width: 100%">
                    </dropdowntree>
                    <span class="dropdown-item-count">{{digitalPassListIds.length}}</span>
                  </div>
                </div>
              </div>

              <div v-if="payload?.enableVoucher" class="row mb-3 k-input-mb-0">
                <div class="col-sm-12">
                  <checkbox :value="this.voucherCheckBoxStatus" @change="this.handleVoucherCheckBoxStatus"
                    :label="'Voucher'" />
                </div>
                <div class="col-md-6 col-sm-12 mt-20" v-if="this.voucherCheckBoxStatus">
                  <label class="mb-0">Assign to Voucher List</label>
                </div>
                <div class="col-md-6 col-sm-12 mt-3" v-if="this.voucherCheckBoxStatus">
                  <div class="dropdown-tree-wrap">
                    <dropdowntree @change="voucherListonChange" :data-source="this.localDataSourceForVoucher"
                      :noDataTemplate="'No lists found.'" :checkboxes="true" :autoClose="false"
                      :placeholder="'Select List'" style="width: 100%">
                    </dropdowntree>
                    <span class="dropdown-item-count">{{voucherCampaignListIds.length}}</span>
                  </div>
                </div>
              </div>

              <div v-if="payload?.enableCampaign" class="row mb-3 k-input-mb-0">
                <div class="col-sm-12">
                  <checkbox :value="emailCampaignCheckBoxStatus" @change="handleEmailCampaignCheckBoxStatus"
                    :label="'Email Campaign'" />
                </div>
                <div class="col-md-6 col-sm-12 mt-20" v-if="emailCampaignCheckBoxStatus">
                  <label class="mb-0">Assign to Campaign List</label>
                </div>
                <div class="col-md-6 col-sm-12 mt-3" v-if="emailCampaignCheckBoxStatus">
                  <div class="dropdown-tree-wrap">
                    <dropdowntree @change="emailCampaignListonChange" :data-source="localDataSourceForEmailCampaign"
                      :noDataTemplate="'No lists found.'" :checkboxes="true" :autoClose="false"
                      :placeholder="'Select List'" style="width: 100%">
                    </dropdowntree>
                    <span class="dropdown-item-count">{{emailCampaignListIds.length}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="pt-3 mt-2">
        <checkbox
          :class="'font-14 font-w-500 cursor-pointer'"
          :value="preferencesCheckBoxStatus"
          @change="handlePreferencesCheckBoxStatus"
          :label="'I confirm that my new member adheres to these conditions:'"
        />
        <ul class="pl-38 list-style-bullet gap-18 mt-3 text-start font-14">
          <li>
            My contact have granted me explicit consent to send emails within
            the past two years or have been asked to do so.
          </li>
          <li>These contact were not obtained from a third party source.</li>
          <li>These contact were not bought or leased.</li>
          <li>
            We reserve the right to halt or terminate email compaigns sent to
            contact
          </li>
        </ul>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton
          @click="this.modalHandler"
          :class="'border-normal-1 text-color'"
          :theme-color="'primary'"
          :fill-mode="'outline'"
          >Cancel</kbutton
        >
      </div>
      <div class="ms-1">
        <kbutton
          :disabled="!firstName || !lastName  || !email "
          @click="this.addMemberModalHandler"
          :theme-color="'primary'"
          :class="''"
          >Add Member</kbutton
        >
      </div>
    </div>
  </template>
</modal>

<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>

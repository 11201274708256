<template src="./MemberListTable.html"></template>
<style lang="scss">
@import "./MemberListTable.scss";
</style>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import MemberImport from "../memberImport/MemberImport";
import MemberUploadSnackbar from "../memberUploadSnackbar/MemberUploadSnackbar";
import GetMembersModel from "../../../../models/member/getMembersModel";
import RemoveMemberModal from "../removeMemberModal/RemoveMemberModal";
import SetStatusActionModal from "../setStatusActionModal/SetStatusActionModal.vue";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import {
  updateSubscriberStatusService,
  getMembersColumnService,
  updateMembersColumnService,
} from "@/services/clubDetails/member.service";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import moment from "moment";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import vClickOutside from "click-outside-vue3";

export default {
  name: "MemberListTable",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    MemberImport,
    checkbox: Checkbox,
    MemberUploadSnackbar,
    RemoveMemberModal,
    DropDownButton,
    SetStatusActionModal,
    AppSnackBar,
    dropdownlist: DropDownList,
    Popup,
    AppLoader,
  },
  data: function () {
    return {
      isColumnLoading: false,
      memberColumns: [],
      dateCells: [],
      allColumns: [],
      sort: [],
      sortBy: "",
      sortDir: "",

      memberFilterPopupVisibility: false,
      itemCode: null,
      moduleCode: "members",
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      clubId: this.$route.params.id,
      selectedUserStatus: 4,
      searchWord: "",
      selectedField: "selected",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      rejectedItems: [],
      payloadDeleteMember: {},
      forceSelectAll: false,
      allChecked: false,
      timer: null,
      selectedStatus: { text: "All", id: 0 },
      statusList: [
        { text: "All", id: 0 },
        { text: "Subscribed", id: 2 },
        { text: "Unsubscribed", id: 3 },
        { text: "Non-subscribed", id: 1 },
        { text: "Cleaned", id: 4 },
        // { text: "Scheduled", id: 7 },
      ],
      actionDropDown: {
        0: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          // { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        1: [
          // { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        2: [
          { id: 1, text: "Subscribed" },
          // { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        3: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          // { id: 3, text: "Cleaned" },
        ],
        4: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      actionType: "",
      actionWarningMessage: "",

      subscriberStatuses: {
        0: "Non-subscribed",
        1: "Subscribed",
        2: "Unsubscribed",
        3: "Cleaned",
      },
    };
  },

  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length == this.membersData?.items?.length &&
        this.membersData?.items?.length != 0
      );
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
          width: "42px",
        },
        ...this?.memberColumns,
      ];
    },
    membersData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.members.members.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }

        return this.$store.state.members.members;
      } else {
        let membersData = this.$store.state.members.members.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.members.members;
      }
    },
    total() {
      return this.membersData ? this.membersData.size : 0;
    },
    take() {
      return this.membersData ? this.membersData.limit : 0;
    },
    skip() {
      return this.membersData ? this.membersData.offset : 0;
    },
    modalVisible() {
      return this.$store.state.members.deleteModalVisible;
    },
    actionmodalVisible() {
      return this.$store.state.members.actionModalVisible;
    },
  },

  mounted() {
    this.getColumns();
  },
  methods: {
    routeToMemberDetails(memberInfo) {
      this.$router.push(
        "/club-details/" +
          this.clubId +
          "/member-list" +
          "/member-details/" +
          memberInfo?.clubUserId
      );
    },
    onColumnSelectionChange(value, index) {
      this.allColumns[index].isChecked = value;
      this.updateColumns(
        {
          id: this.allColumns[index].id,
          isChecked: value,
        },
        index
      );
    },
    updateColumns(columnData, index) {
      this.isColumnLoading = true;
      let payload = {
        clubId: this.clubId,
        ...columnData,
      };
      updateMembersColumnService(payload)
        .then((res) => {
          this.isColumnLoading = false;
          this.memberColumns = this.culumnGenerator(this.allColumns);
        })
        .catch((error) => {
          this.isColumnLoading = false;
          this.allColumns[index].isChecked = !columnData.isChecked;
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              error.response.data?.Status?.Message ||
              "An error occurred while updating the columns. Please try again later.",
            type: "error",
          });
        });
    },
    culumnGenerator(data) {
      let columns = [];

      data.forEach((element) => {
        if (element.isChecked) {
          if (["Name", "Status"].includes(element.title)) {
            columns.push({
              field: element.field,
              title: element.title,
              cell: element.field + "Cell",
              sortable: element?.sortable,
              width: element.title == "Status" ? "150px" : "",
            });
          } else {
            if (element.type == 4) {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
                sortable: element?.sortable,
                width: "150px",
              });

              this.dateCells.push({
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
              });
            } else {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                sortable: element?.sortable,
              });
            }
          }
        }
      });

      return columns;
    },
    getColumns() {
      this.isColumnLoading = true;
      getMembersColumnService({ clubId: this.clubId })
        .then((res) => {
          this.isColumnLoading = false;
          this.allColumns = res.data;
          this.memberColumns = this.culumnGenerator(res.data);
        })
        .catch((err) => {
          this.isColumnLoading = false;
        });
    },
    payloadGenerator() {
      let payload = new GetMembersModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        searchText: this.searchWord,
        ...(this.selectedStatus.id != 0 && {
          subscriberStatusFilter: this.selectedStatus.id,
        }),
      };

      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }

      return payload;
    },
    refetchMembers(resetPagination = false) {
      let payload = this.payloadGenerator();

      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      } else {
        payload = {
          ...payload,
          limit: this.take,
          offset: this.skip,
        };
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          limit: payload.limit,
          offset: payload.offset,
        },
      });

      this.$store.dispatch("getMembers", payload);
    },
    handleValueChange(value, field) {
      this[field] = value;
      this.refetchMembers(true);
    },
    memberFilterHandler() {
      this.memberFilterPopupVisibility = !this.memberFilterPopupVisibility;
    },
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    getDateLocal(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "-";
      }
    },
    showNotification(message, type, time = 5000) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      const timerId = setTimeout(() => {
        this.notifyMessage = "";
        this.notifyType = "success";
        this.isNotify = false;
        clearTimeout(timerId);
      }, time);
    },

    allEqualChecker(arr) {
      return arr.every(
        (val) => val?.subscriberStatus === arr[0]?.subscriberStatus
      );
    },

    userSubscribedStatusHandler() {
      const selectedArray = this.membersData.items.filter(
        (item) => item.selected
      );
      // , "c92");
      if (this.allEqualChecker(selectedArray)) {
        this.selectedUserStatus = selectedArray[0]?.subscriberStatus;
      } else {
        this.selectedUserStatus = 4;
      }
    },

    onHeaderSelectionChange(event) {
      this.selectedUserStatus = 4;
      this.allChecked = event.event.target.checked;
      this.membersData.items = this.membersData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      // ;
      // ;
      if (this.allChecked) {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].id);
            }
          }
        }
      } else {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].id) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
      this.userSubscribedStatusHandler();
    },
    onSelectionChange(event) {
      this.selectedUserStatus = event?.dataItem?.subscriberStatus;
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.id);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.id) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                if (rejectedData[key] === event.dataItem.id) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.id);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.id) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                if (selectedData[key] === event.dataItem.id) {
                  selectedData.splice(key, 1);
                }
              }
            }
            this.selectedItems = selectedData;
          }
        }
      }
      this.userSubscribedStatusHandler();
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.$store.commit("SET_LOADER", true);

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refetchMembers(true);
      }, 800);
    },
    dataStateChange: function (event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = this.payloadGenerator();
      payload.limit = event.data.take;
      payload.offset = event.data.skip;

      // this.$router.push({
      //   query: {
      //     ...this.$route.query,
      //     limit: payload.limit,
      //     offset: payload.offset,
      //   },
      // });

      this.$store.dispatch("getMembers", payload);
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.refetchMembers(true);
    },
    deleteMember() {
      this.$store.commit("DELETE_MEMBER_MODAL_VISIBLE", true);
    },
    onActionClick(e) {
      if (e?.item?.id == 0) {
        this.actionType = 0;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} member(s) to non-subscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 1) {
        this.actionType = 1;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} member(s) to subscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 2) {
        this.actionType = 2;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} member(s) to unsubscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 3) {
        this.actionType = 3;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} member(s)  to cleaned. Are you sure you want to proceed with this action?`;
      }
      const timerId = setTimeout(() => {
        this.$store.commit("ACTION_MODAL_VISIBLE", true);
        clearTimeout(timerId);
      }, 100);
    },
    onConfirmActionHandler() {
      this.$store.commit("ACTION_MODAL_VISIBLE", false);

      const payload = {
        clubId: this.clubId,
        userIds: this.selectedItems,
        subscriberStatus: this.actionType,
      };
      this.$store.commit("LOADING_STATUS_MEMBER", true);
      updateSubscriberStatusService(payload)
        .then((res) => {
          this.$store.commit("LOADING_STATUS_MEMBER", false);
          this.selectedItems = [];
          this.refetchMembers(true);
          this.showNotification(
            `The subscription status of the selected member(s) has been successfully updated.`,
            "success"
          );
        })
        .catch((err) => {
          this.$store.commit("LOADING_STATUS_MEMBER", false);
          this.showNotification(
            "An error occurred while updating the subscription status of the selected member(s). Please try again later.",
            "error"
          );
        });
    },
    completeDelete() {
      this.selectedItems = [];
    },
  },
};
</script>

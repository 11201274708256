<!--dashboard-content-->
<modal
  :class="'max-height-100-145'"
  title="Import Members"
  :handler="closeMapModal"
  :cssClass="'modal-md-5-480'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div class="width-482 mx-auto">
      <div
        class="file-upload-wrap file-upload-custom-main mb-0"
        :class="`${(this.files.length > 0 && !this.isVisible) ? 'uploaded': ''}`"
      >
        <div class="custom-upload-box">
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
            <rect
              x="3"
              y="3"
              width="40"
              height="40"
              rx="20"
              stroke="#F9FAFB"
              stroke-width="6"
            />
            <g clip-path="url(#clip0_14552_49856)">
              <path
                d="M26.3326 26.3333L22.9992 23M22.9992 23L19.6659 26.3333M22.9992 23V30.5M29.9909 28.325C30.8037 27.8819 31.4458 27.1807 31.8158 26.3322C32.1858 25.4836 32.2627 24.536 32.0344 23.6389C31.8061 22.7418 31.2855 21.9463 30.5548 21.3779C29.8241 20.8095 28.925 20.5006 27.9992 20.5H26.9492C26.697 19.5244 26.2269 18.6186 25.5742 17.8508C24.9215 17.083 24.1033 16.4732 23.181 16.0672C22.2587 15.6611 21.2564 15.4695 20.2493 15.5066C19.2423 15.5437 18.2568 15.8086 17.3669 16.2814C16.477 16.7542 15.7058 17.4226 15.1114 18.2363C14.517 19.0501 14.1148 19.9879 13.9351 20.9795C13.7553 21.971 13.8027 22.9904 14.0736 23.9611C14.3445 24.9317 14.8319 25.8282 15.4992 26.5833"
                stroke="#475467"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_14552_49856">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(13 13)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <span
          v-if="this.isVisible"
          class="file-info-hints font-14 font-w-600 text-center"
        >
          <span class="display-inline-block text-center">
            <span class="color-blue">Click to upload</span> or drag and drop
            <br />
            CSV, XLS or XLX file
          </span>
        </span>

        <upload
          :disabled="isDisabled"
          :batch="false"
          :multiple="false"
          :files="files"
          @add="onAdd"
          @remove="onRemove"
          @statuschange="onStatusChange"
          :with-credentials="false"
          :restrictions="{allowedExtensions}"
          :accept="allowedExtensions"
          :save-url="uploadDocument"
          :remove-url="onRemoveRequest(files)"
          @progress="onProgress"
        ></upload>

        <div class="error-msg text-center w-100">{{validationErrors}}</div>
      </div>

      <div v-if="false" class="uploaded-files">
        <div class="uploaded-file-single p-16 d-flex gap-3 mt-16">
          <div class="icon">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="2" y="2" width="32" height="32" rx="16" fill="#EBF3FF" />
              <rect
                x="2"
                y="2"
                width="32"
                height="32"
                rx="16"
                stroke="#F5F9FF"
                stroke-width="4"
              />
              <path
                d="M18.666 11.3333H13.9993C13.6457 11.3333 13.3066 11.4738 13.0565 11.7239C12.8065 11.9739 12.666 12.313 12.666 12.6667V23.3333C12.666 23.687 12.8065 24.0261 13.0565 24.2761C13.3066 24.5262 13.6457 24.6667 13.9993 24.6667H21.9993C22.353 24.6667 22.6921 24.5262 22.9422 24.2761C23.1922 24.0261 23.3327 23.687 23.3327 23.3333V16M18.666 11.3333L23.3327 16M18.666 11.3333V16H23.3327"
                stroke="#0052CC"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="infos w-100">
            <p class="font-14 font-w-500 m-0 text-color-3">
              member_list_2024.CSV
            </p>
            <p class="font-14 font-w-400 m-0 text-color-2">1.2 MB</p>
            <ProgressBar :class="'custom-styled-progressbar'" :value="40" />
          </div>
          <div class="delete-button ms-auto">
            <kbutton :fill-mode="'flat'" :class="'p-0'">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 13H12.1667M12.1667 13H25.5M12.1667 13V24.6667C12.1667 25.1087 12.3423 25.5326 12.6548 25.8452C12.9674 26.1577 13.3913 26.3333 13.8333 26.3333H22.1667C22.6087 26.3333 23.0326 26.1577 23.3452 25.8452C23.6577 25.5326 23.8333 25.1087 23.8333 24.6667V13H12.1667ZM14.6667 13V11.3333C14.6667 10.8913 14.8423 10.4674 15.1548 10.1548C15.4674 9.84227 15.8913 9.66667 16.3333 9.66667H19.6667C20.1087 9.66667 20.5326 9.84227 20.8452 10.1548C21.1577 10.4674 21.3333 10.8913 21.3333 11.3333V13M16.3333 17.1667V22.1667M19.6667 17.1667V22.1667"
                  stroke="#667085"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </kbutton>
          </div>
        </div>
      </div>
    </div>

    <MemberMappingModal
      v-if="memberModalVisible && importMembersDocId"
      :documentId="importMembersDocId"
      @parentModalhandler="parentModalhandler"
    />
  </template>

  <template v-slot:footer :class="'justify-content-end'">
    <div class="d-flex justify-content-end">
      <div class="">
        <kbutton
          @click="closeMemberImportModal"
          :class="'border-normal-1 text-color'"
          :theme-color="'primary'"
          :fill-mode="'outline'"
          >Cancel</kbutton
        >
      </div>
      <div class="ml-16">
        <kbutton
          :disabled="!importMembersDocId"
          :theme-color="'primary'"
          @click="openMemberImportModal"
        >
          Continue to Field Mapping</kbutton
        >
      </div>
    </div>
  </template>
</modal>

<template src="./MemberUploadSnackbar.html"></template>

<script>
import modal from "../../../common/AppModal/AppModal";
import { Button } from "@progress/kendo-vue-buttons";

export default {
  components: {
    modal,
    kbutton: Button,
  },

  props: {
    clubId: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {};
  },

  computed: {
    importMemberRes() {
      return this.$store.state.members.importMembersResponse?.data?.data;
    },
    modalVisible(){
      return this.$store.state.members.loader;
    }
  },

  methods: {
    modalHandler(data) {
      this.$store.commit("SET_IMPORT_LOADER", false);
    },
    downloadLog(){
      this.$store.dispatch("downloadLog",this.clubId);
    }
  },
};
</script>

<style>
</style>
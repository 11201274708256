<template src="./AddIndividualMemberModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import { getLists } from "@/services/clubDetails/list.service";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { filterBy } from "@progress/kendo-data-query";

export default {
  props: {
    payload: {
      type: Object,
    },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    "k-input": Input,
    dropdownlist: DropDownList,
    MaskedTextBox: MaskedTextBox,
    checkbox: Checkbox,
    dropdowntree: DropDownTree,
    AppSnackBar,
  },
  data() {
    return {
      clubId: this.$route.params.id,
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      mobile: "",
      timeZoneValue: "",
      joiningDate: new Date(),
      genderItems: [
        { id: 1, text: "Male" },
        { id: 2, text: "Female" },
        { id: 3, text: "Unspecified" },
      ],
      localDataSourceForDigitalPass: [],
      digitalPassListIds: [],
      localDataSourceForVoucher: [],
      localDataSourceForEmailCampaign: [],
      voucherCampaignListIds: [],
      emailCampaignListIds: [],
      genderValue: { id: 1, text: "Male" },
      mask: "(+880) 0000-000000",
      // defaultValue: "(+880) 1309-990581",
      digitalPassCheckBoxStatus: false,
      preferencesCheckBoxStatus: false,
      voucherCheckBoxStatus: false,
      emailCampaignCheckBoxStatus: false,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      searchData: "",
    };
  },
  watch: {
    timeZonesList(newVal) {
      const timeZone = this.club.timeZoneId;
      this.timeZoneValue = this.timeZonesList.find((item) => {
        return item.id === timeZone;
      });
    },
  },
  mounted() {
    this.getDigitalPassList();
    this.getVouchersList();
    this.getEmailCampaignList();
    this.$store.dispatch("getTimeZonesList", 999);
  },
  computed: {
    addMemberErrors() {
      return this.$store.state.members.addMemberErrors;
    },
    addIndividualMemberModalLoader() {
      return this.$store.state.members.addIndividualMemberModalLoader;
    },
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    club() {
      return this.$store.state.club.club;
    },
  },
  methods: {
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.commonEmailCampaign.timeZoneTempList;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_TIME_ZONE_LIST", this.searchData);
    },
    timeZoneInit() {
      let timeZone = this.club.timeZoneId;
      if (timeZone != 999) {
        return this.timeZonesList.find((item) => item.id === timeZone);
      } else {
        return this.timeZonesList.find(
          (item) =>
            item.name === Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      }
    },
    clearEmailError() {
      if (this.addMemberErrors.Email) {
        this.addMemberErrors.Email[0] = "";
      }
      this.emailError = "";
    },
    digitalPassListonChange(event) {
      this.digitalPassListIds = event.sender._values;
    },
    voucherListonChange(event) {
      this.voucherCampaignListIds = event.sender._values;
    },
    emailCampaignListonChange(event) {
      this.emailCampaignListIds = event.sender._values;
    },
    async getDigitalPassList() {
      const payload = {
        clubId: this.$route.params.id,
        entityType: 1,
        limit: -1,
      };

      try {
        const result = await getLists(payload);
        if (result?.data?.data?.items?.length > 0) {
          result?.data?.data?.items.forEach((list) => {
            this.localDataSourceForDigitalPass.push({
              text: list.name,
              value: list.id,
            });
          });
        }
      } catch (error) {}
    },
    async getVouchersList() {
      const payload = {
        clubId: this.$route.params.id,
        entityType: 3,
        limit: -1,
      };
      try {
        const result = await getLists(payload);
        if (result?.data?.data?.items?.length > 0) {
          result?.data?.data?.items.forEach((list) => {
            this.localDataSourceForVoucher.push({
              text: list.name,
              value: list.id,
            });
          });
        }
      } catch (error) {
        // ;
      }
    },
    async getEmailCampaignList() {
      const payload = {
        clubId: this.$route.params.id,
        entityType: 2,
        limit: -1,
      };
      try {
        const result = await getLists(payload);
        if (result?.data?.data?.items?.length > 0) {
          result?.data?.data?.items.forEach((list) => {
            this.localDataSourceForEmailCampaign.push({
              text: list.name,
              value: list.id,
            });
          });
        }
      } catch (error) {
        // ;
      }
    },
    handleDigitalPassCheckBoxStatus(event) {
      this.digitalPassCheckBoxStatus = event.value;
    },
    handlePreferencesCheckBoxStatus(event) {
      this.preferencesCheckBoxStatus = event.value;
    },
    handleVoucherCheckBoxStatus(event) {
      this.voucherCheckBoxStatus = event.value;
    },
    handleEmailCampaignCheckBoxStatus(event) {
      this.emailCampaignCheckBoxStatus = event.value;
    },
    modalHandler() {
      this.$store.commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_VISIBILITY", false);
    },
    handleGenderChangeValue(e) {
      this.genderValue = e.value;
    },
    handleTimeZoneChangeValue(e) {
      this.timeZoneValue = e.value;
    },
    dataValidation() {
      let returnValue = true;

      function onlyNumbers(str) {
        return /^[0-9.,]+$/.test(str);
      }

      function onlySpaces(str) {
        return /^\s*$/.test(str);
      }

      if (!this.email) {
        this.emailError = "Email is required.";
        returnValue = false;
      }

      if (!this.firstName) {
        this.firstNameError = "First Name is required.";
        returnValue = false;
      }

      if (!this.lastName) {
        this.lastNameError = "Last Name is required.";
        returnValue = false;
      }

      if (onlySpaces(this.firstName)) {
        this.firstNameError = "Invalid name.";
        returnValue = false;
      }

      if (onlySpaces(this.lastName)) {
        this.lastNameError = "Invalid name.";
        returnValue = false;
      }

      if (onlyNumbers(this.firstName)) {
        this.firstNameError = "Invalid name.";
        returnValue = false;
      }

      if (onlyNumbers(this.lastName)) {
        this.lastNameError = "Invalid name.";
        returnValue = false;
      }

      if (this.firstName.length > 25) {
        this.firstNameError = "Enter less than 25 characters.";
        returnValue = false;
      }

      if (this.lastName.length > 25) {
        this.lastNameError = "Enter less than 25 characters.";
        returnValue = false;
      }

      if (this.email.length > 50) {
        this.emailError = "Enter less than 50 characters.";
        returnValue = false;
      }

      if (this.email) {
        const emailValidation = () => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(this.email).toLowerCase());
        };
        if (!emailValidation()) {
          this.emailError = "Please provide a valid email address.";
          returnValue = false;
        }
      }
      return returnValue;
    },
    addMemberModalHandler() {
      if (this.dataValidation()) {
        this.$store.commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_LOADER", true);

        const payload = {
          clubId: this.clubId,
          firstName: this.firstName,
          lastName: this.lastName,
          joiningDate: this.joiningDate,
          timezone: this.timeZoneValue?.name,
          email: this.email,
          mobile: this.mobile,
          genderId: this.genderValue.id,
          subscriberStatus: this.preferencesCheckBoxStatus ? 1 : 0,
        };

        if (this.digitalPassCheckBoxStatus) {
          payload.assignToDigitalPassLists = true;
          payload.digitalPassListIds = this.digitalPassListIds;
        }

        if (this.voucherCheckBoxStatus) {
          payload.assignToVoucherCampaignLists = true;
          payload.voucherCampaignListIds = this.voucherCampaignListIds;
        }
        if (this.emailCampaignCheckBoxStatus) {
          payload.assignToEmailCampaignLists = true;
          payload.emailCampaignListIds = this.emailCampaignListIds;
        }
        this.$store.dispatch("addIndividualMember", payload);
      }
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>

<style></style>

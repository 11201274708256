<!--dashboard-content-->
<div
  class="dashboard-content bg-white radius-5 min-height-calc-100-182 d-flex justify-content-center align-items-center">
  <div class="width-747 text-center">
    <svg width="217" height="184" viewBox="0 0 217 184" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.05" cx="108" cy="92" r="92" fill="#253858" />
      <g filter="url(#filter0_bdd_666_102160)">
        <rect x="31.7822" y="53.0176" width="154.563" height="98.2819" rx="1.93432" fill="white" fill-opacity="0.15"
          shape-rendering="crispEdges" />
      </g>
      <g filter="url(#filter1_bd_666_102160)">
        <rect x="28.0996" y="43.9238" width="161.928" height="102.965" rx="1.88497" fill="white" fill-opacity="0.15"
          shape-rendering="crispEdges" />
      </g>
      <g filter="url(#filter2_dd_666_102160)">
        <rect x="23" y="33" width="170.842" height="108.97" rx="3.69388" fill="white" />
      </g>
      <line x1="36" y1="86.5383" x2="180" y2="86.5383" stroke="black" stroke-opacity="0.04" stroke-width="0.923471" />
      <rect opacity="0.2" x="38" y="47" width="27.7041" height="27.7041" rx="13.8521" fill="#14C57B" />
      <rect opacity="0.6" x="76" y="49" width="55" height="6" rx="3" fill="#C7CBDB" />
      <rect opacity="0.3" x="76" y="62" width="104" height="3.5" rx="1.75" fill="#C7CBDB" />
      <rect opacity="0.3" x="76" y="71" width="94" height="3.5" rx="1.75" fill="#C7CBDB" />
      <mask id="mask0_666_102160" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="38" y="45" width="28"
        height="28">
        <rect x="38" y="45" width="27.7041" height="27.7041" rx="13.8521" fill="#A4C3F3" />
      </mask>
      <g mask="url(#mask0_666_102160)">
        <path
          d="M48.6959 62.9591C49.846 62.4912 50.9474 62.2573 52 62.2573C53.0526 62.2573 54.1442 62.4912 55.2749 62.9591C56.425 63.4074 57 64.0019 57 64.7427C57 65.4371 56.4371 66 55.7427 66H48.2573C47.5629 66 47 65.4371 47 64.7427C47 64.0019 47.5653 63.4074 48.6959 62.9591ZM53.7544 60.269C53.2671 60.7563 52.6823 61 52 61C51.3177 61 50.7329 60.7563 50.2456 60.269C49.7583 59.7817 49.5146 59.1969 49.5146 58.5146C49.5146 57.8324 49.7583 57.2476 50.2456 56.7602C50.7329 56.2534 51.3177 56 52 56C52.6823 56 53.2671 56.2534 53.7544 56.7602C54.2417 57.2476 54.4854 57.8324 54.4854 58.5146C54.4854 59.1969 54.2417 59.7817 53.7544 60.269Z"
          fill="#14C57B" />
      </g>
      <rect opacity="0.2" x="38" y="98" width="27.7041" height="27.7041" rx="13.8521" fill="#9B5FFC" />
      <rect opacity="0.6" x="76" y="100" width="55" height="6" rx="3" fill="#C7CBDB" />
      <rect opacity="0.3" x="76" y="113" width="104" height="3.5" rx="1.75" fill="#C7CBDB" />
      <rect opacity="0.3" x="76" y="122" width="94" height="3.5" rx="1.75" fill="#C7CBDB" />
      <mask id="mask1_666_102160" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="38" y="96" width="28"
        height="28">
        <rect x="38" y="96" width="27.7041" height="27.7041" rx="13.8521" fill="#A4C3F3" />
      </mask>
      <g mask="url(#mask1_666_102160)">
        <path
          d="M48.6959 112.959C49.846 112.491 50.9474 112.257 52 112.257C53.0526 112.257 54.1442 112.491 55.2749 112.959C56.425 113.407 57 114.002 57 114.743C57 115.437 56.4371 116 55.7427 116H48.2573C47.5629 116 47 115.437 47 114.743C47 114.002 47.5653 113.407 48.6959 112.959ZM53.7544 110.269C53.2671 110.756 52.6823 111 52 111C51.3177 111 50.7329 110.756 50.2456 110.269C49.7583 109.782 49.5146 109.197 49.5146 108.515C49.5146 107.832 49.7583 107.248 50.2456 106.76C50.7329 106.253 51.3177 106 52 106C52.6823 106 53.2671 106.253 53.7544 106.76C54.2417 107.248 54.4854 107.832 54.4854 108.515C54.4854 109.197 54.2417 109.782 53.7544 110.269Z"
          fill="#9B5FFC" />
      </g>
      <defs>
        <filter id="filter0_bdd_666_102160" x="23.7431" y="44.9784" width="170.641" height="114.36"
          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="4.01957" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_666_102160" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1.81524" />
          <feGaussianBlur stdDeviation="1.81524" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_666_102160" result="effect2_dropShadow_666_102160" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="4.01957" />
          <feGaussianBlur stdDeviation="2.00978" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_666_102160" result="effect3_dropShadow_666_102160" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_666_102160" result="shape" />
        </filter>
        <filter id="filter1_bd_666_102160" x="20.3047" y="36.1289" width="177.518" height="118.555"
          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_666_102160" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1.76009" />
          <feGaussianBlur stdDeviation="1.76009" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_666_102160" result="effect2_dropShadow_666_102160" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_666_102160" result="shape" />
        </filter>
        <filter id="filter2_dd_666_102160" x="0.8367" y="18.2245" width="215.169" height="153.296"
          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="7.38777" />
          <feGaussianBlur stdDeviation="11.0816" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_666_102160" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1.84694" />
          <feGaussianBlur stdDeviation="1.84694" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_666_102160" result="effect2_dropShadow_666_102160" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_666_102160" result="shape" />
        </filter>
      </defs>
    </svg>

    <p v-if="nullDataInitializer" class="color-light2 mt-3 mb-5">No Members are imported yet. Click on the Import
      Members button to import the CSV, XLS or XLSX file consisting of your member information.</p>

    <div class="width-482 mx-auto">
      <div
        v-bind:class="importMembersDocId ? 'file-upload-wrap file-upload-custom-main selected' : 'file-upload-wrap file-upload-custom-main'">

        <div class="custom-upload-box">
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
            <rect
              x="3"
              y="3"
              width="40"
              height="40"
              rx="20"
              stroke="#F9FAFB"
              stroke-width="6"
            />
            <g clip-path="url(#clip0_14552_49856)">
              <path
                d="M26.3326 26.3333L22.9992 23M22.9992 23L19.6659 26.3333M22.9992 23V30.5M29.9909 28.325C30.8037 27.8819 31.4458 27.1807 31.8158 26.3322C32.1858 25.4836 32.2627 24.536 32.0344 23.6389C31.8061 22.7418 31.2855 21.9463 30.5548 21.3779C29.8241 20.8095 28.925 20.5006 27.9992 20.5H26.9492C26.697 19.5244 26.2269 18.6186 25.5742 17.8508C24.9215 17.083 24.1033 16.4732 23.181 16.0672C22.2587 15.6611 21.2564 15.4695 20.2493 15.5066C19.2423 15.5437 18.2568 15.8086 17.3669 16.2814C16.477 16.7542 15.7058 17.4226 15.1114 18.2363C14.517 19.0501 14.1148 19.9879 13.9351 20.9795C13.7553 21.971 13.8027 22.9904 14.0736 23.9611C14.3445 24.9317 14.8319 25.8282 15.4992 26.5833"
                stroke="#475467"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_14552_49856">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(13 13)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>

        <span class="file-info-hints font-14 font-w-600 text-center">
          <span class="display-inline-block text-center" v-if="importFileName">
            {{ isNameLimit(importFileName) }}
          </span>
          <span class="display-inline-block text-center" v-else="importFileName">
            <span class="color-blue">Click to upload</span> or drag and drop <br> CSV, XLS or XLX file
          </span>
          <kbutton v-if="importMembersDocId" :class="'small-btn-icon'" @click='removeDocument'>
            <span class="k-icon k-i-close"></span>
          </kbutton>
        </span>

        <upload :disabled="isDisabled || !hasCreateAccess()" :batch="false" :multiple="false" :files="files" @add="onAdd" @remove="onRemove"
          @progress="onProgress" :accept="allowedExtensions" :restrictions="{allowedExtensions}"
          @statuschange="onStatusChange" :with-credentials="false" :save-url="uploadDocument"></upload>

        <div class="error-msg text-center w-100">{{validationErrors}}</div>
      </div>


      <kbutton v-if="importMembersDocId" :class="'w-100'" :theme-color="'primary'" @click='openMemberImportModal'>
        Continue to Field Mapping</kbutton>

    </div>

    <div class="width-482 mx-auto">
      <h5>Or</h5>
      <kbutton :disabled="!hasCreateAccess()" :class="'px-47 w-100'" :theme-color="'primary'" @click="openModalForAddMember">Add Member
      </kbutton>
    </div>

    <MemberMappingModal :payload="payloadListTagAssign" v-if="memberModalVisible && importMembersDocId"
      :documentId="importMembersDocId" @parentModalhandler="parentModalhandler" />

  </div>
</div>
<modal :title="'Import Complete!'"  :handler="modalVisible" :cssClass="'c-modal-lg snack-bar-popup justify-content-start'" @modalHandler="modalHandler">
    <template v-slot:content>
        <p v-if="importMemberRes?.errorCount" class="text-center mb-2 pb-1 font-14"><span class="k-icon k-i-check-outline"></span> <strong class="text-success">{{importMemberRes?.importCount}} members</strong> Successfully imported.</p>
        <p v-if="!importMemberRes?.errorCount" class="text-center mb-2 pb-1 font-14">Successfully imported<span class="k-icon k-i-check-outline"></span> <strong class="text-success">{{importMemberRes?.importCount}} members</strong> to the list. Check out the members section to see the complete list of imported members.</p>
        <p v-if="importMemberRes?.errorCount" class="text-center mb-2 pb-1 font-14"><span class="k-icon k-i-warning"></span> Failed to Import <strong class="text-danger">{{importMemberRes?.errorCount}} members</strong> to the list.</p>
        <p v-if="importMemberRes?.errorCount" class="text-center mb-2 pb-1 font-14">Download report by clicking View Logs button for details on failed imports.</p>
        <div class="text-center mb-2 pb-1 font-14">
            <kbutton v-if="importMemberRes?.errorCount" @click="downloadLog" :icon="'file-txt'" :fill-mode="'flat'" :theme-color="'primary'"> View Logs </kbutton>
        </div>
    </template>
</modal>

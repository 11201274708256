<template src="./RemoveMemberModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    selectedItems: {
      type: [],
      required: true,
    },
  },
  computed: {
    modalVisible() {
      return this.$store.state.members.deleteModalVisible;
    },
    club() {
      return this.$store.getters.clubDetails;
    },
    deleteMemberLoading() {
      return this.$store.state.members.loading_delete;
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
    };
  },
  methods: {
    cancelModal() {
      this.$store.commit("DELETE_MEMBER_MODAL_VISIBLE", false);
    },
    deleteClub() {
      let payload = {
        clubId: this.clubId,
        userIds: this.selectedItems,
      };
      this.$store.dispatch("deleteMembers", payload);
      this.$emit("completeDelete");
    },
    modalHandler(data) {
      this.$store.commit("DELETE_MEMBER_MODAL_VISIBLE", false);
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>

<template src="./MemberImport.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Upload } from "@progress/kendo-vue-upload";
import myUpload from "vue-image-crop-upload";
import MemberMappingModal from "../memberMappingModal/MemberMappingModal.vue";
import { configuration } from "@/configurationProvider";

const fileStatuses = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing",
];

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    upload: Upload,
    myUpload,
    MemberMappingModal,
  },
  props: {
    nullDataInitializer: {
      type: Boolean,
      default: true,
    },
    payloadListTagAssign: {
      type: Object,
    },
  },
  computed: {
    memberModalVisible() {
      return this.$store.state.members.memberModalVisible;
    },
  },
  data: function () {
    var url = configuration.apiBaseUrl;

    return {
      itemCode: null,
      moduleCode: "members",
      visibleDialog: true,
      show: true,
      url,
      uploadDocument: url + "/api/v1/Document?clubId=" + this.$route.params.id,
      importMembersDocId: "",
      removeDocumentRes: "",
      importFileName: "",
      files: [],
      events: [],
      filePreviews: {},
      allowedExtensions: [".csv", ".xls", ".xlsx"],
      validationErrors: "",
      isDisabled: false,
    };
  },

  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    onAdd(event) {
      if (event.affectedFiles[0]?.validationErrors) {
        this.validationErrors =
          "File type not supported. Supported file types are CSV, XLS and XLSX.";
      }
    },
    onRemove(event) {
      // ;
      // const filePreviews = {
      //   ...this.filePreviews,
      // };
      // event.affectedFiles.forEach((file) => {
      //   delete filePreviews[file.uid];
      // });
      // this.files = event.newState;
      // this.events.push(`File removed: ${event.affectedFiles[0].name}`);
      // this.filePreviews = filePreviews;
    },
    onProgress(event) {
      // this.files = event.newState;
      // this.events.push(`On Progress: ${event.affectedFiles[0].progress} %`);
    },
    onStatusChange(event) {
      this.isDisabled = true;
      this.validationErrors = "";
      this.importMembersDocId = event.response.response.id;
      const file = event.affectedFiles[0];
      // ;
      this.documentId = event.response.response.id;
      this.importFileName = event.response.response.originalName;
      this.files = event.newState;
      this.events.push(
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`
      );
    },
    openMemberImportModal() {
      // this.modalVisible = true;
      // this.$emit("modalHandler", false);
      var callBack = {
        doccumentId: this.importMembersDocId,
        isModalOpen: true,
      };
      this.$emit("openMemberMapping", callBack);
      this.$store.commit("IMPORT_MODAL_VISIBLE", true);
    },
    // documnetId() {
    //   this.$emit("getDocumentId",  this.importMembersDocId);
    //   this.$emit('close');
    // },
    removeDocument() {
      this.$store.dispatch("deleteDocuments", this.importMembersDocId);
      this.importMembersDocId = "";
      this.importFileName = "";
      this.isDisabled = false;
    },
    parentModalhandler(data) {
      ;
      this.$emit("modalHandler", false);
    },
    isNameLimit(name) {
      ;
      return name.length <= 50 ? name : name.substring(0, 50) + "...";
    },
    openModalForAddMember() {
      this.$store.commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_VISIBILITY", true);
    },
  },
};
</script>

<app-loader v-if="memberLoading"></app-loader>

<AppSnackBar :message="showMsg.Message" :notificationType="showMsg.type" v-if="isShowMsg"></AppSnackBar>
<AppSnackBar :message="showSuccessMsg.Message" :notificationType="showSuccessMsg.type" v-if="isShowSuccessMsg">
</AppSnackBar>
<AppSnackBar :message="showErrorMsg.Message" :notificationType="showErrorMsg.type" v-if="isShowErrorMsg">
</AppSnackBar>

<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />
  <div class="max-height-100--60 overflow-y-auto" ref="scrollableDiv" @scroll="handleScroll">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div class="px-20 px-10-sm">
        <div v-if="fetchController == 'available' || isSearching?.length || anyFilterExistInMembers"
          class="d-flex flex-wrap justify-content-between align-items-center w-100-vsm pt-12 pb-16 w-100 border-bottom-normal-1 mb-16">
          <h2 class="font-18 font-w-600 mb-2 mb-sm-0">Members</h2>
          <div class="w-100-vsm d-flex flex-wrap gap-3">
            <kbutton :disabled="!hasCreateAccess()" @click="exportList" :fill-mode="'outline'"
              :class="'w-100-vsm h-38 font-w-500 border-normal-1 base-color bg-white hvr-bg-primary'"
              :theme-color="'primary'">Export Members</kbutton>
            <kbutton :disabled="!hasCreateAccess()" @click="openImportModal" :fill-mode="'outline'"
              :class="'w-100-vsm h-38 font-w-500 border-normal-1 base-color bg-white hvr-bg-primary'"
              :theme-color="'primary'">
              Import Members
            </kbutton>
            <kbutton :disabled="!hasCreateAccess()" @click="openModalForAddMember" :class="'w-100-vsm h-38 font-w-500'"
              :theme-color="'primary'">
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 7.5V3.5H7V7.5H3V9.5H7V13.5H9V9.5H13V7.5H9Z" fill="white" />
              </svg>
              Add Member
            </kbutton>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex flex-wrap justify-content-between px-20 pt-24 pb-0">
        <div class="d-flex flex-wrap align-items-start gap-2">
          <div
            class="search-box-wrap width-220 w-100-vsm mb-12 me-0 me-sm-3 me-md-4"
          >
            <span class="k-icon k-i-zoom"></span>
            <k-input :class="'mb-0'" placeholder="Search"> </k-input>
          </div>
        </div>
        <div class="d-flex flex-wrap gap-2">
          <div class="w-100-vsm mb-9">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                >Filter by Status</label
              >
              <dropdownlist :class="'w-100-vsm'" :style="{ width: '140px' }">
              </dropdownlist>
            </span>
          </div>
          <div class="position-relative">
            <kbutton
              @click="memberFilterHandler"
              ref="buildFromScratchRef"
              :fill-mode="'outline'"
              :class="'h-38 w-38 border-normal-1 text-color'"
              :theme-color="'primary'"
              :icon="'table-properties'"
            ></kbutton>
            <Popup
              :anchor="'buildFromScratchRef'"
              :show="memberFilterPopupVisibility"
              :popup-class="'member-filter-popup column-filter-popup w-219 radius-8'"
            >
              <div class="p-16 d-flex flex-wrap flex-column gap-3">
                <checkbox :class="'font-16 cursor-pointer'" :label="'Name'" />
                <checkbox :class="'font-16 cursor-pointer'" :label="'Email'" />
                <checkbox :class="'font-16 cursor-pointer'" :label="'Status'" />
                <checkbox
                  :class="'font-16 cursor-pointer'"
                  :label="'Joining Date'"
                />
                <checkbox :class="'font-16 cursor-pointer'" :label="'Phone'" />
                <checkbox :class="'font-16 cursor-pointer'" :label="'Gender'" />
                <checkbox
                  :class="'font-16 cursor-pointer'"
                  :label="'Time Zone'"
                />
                <checkbox
                  :class="'font-16 cursor-pointer'"
                  :label="'Address 1'"
                />
                <checkbox
                  :class="'font-16 cursor-pointer'"
                  :label="'Address 2'"
                />
                <checkbox :class="'font-16 cursor-pointer'" :label="'City'" />
              </div>
            </Popup>
          </div>
        </div>
      </div> -->
    </div>
    <div class="dashboard-content py-0 px-20 px-10-sm">
      <div>
        <MemberImportModal v-if="importModalVisible && closeMemberImport"
          @closeMemberImportModal="getCloseMemberImportModal" @openMemberMapping="getopenMemberMapping"
          :nullDataInitializer="false" @modalHandler="modalHandler" />
        <MemberMappingModal :payload="getClubDetails" :dialogue="false" v-if="openMembermapping"
          @closeAllModals="closeAllModals" @closeMemberMapping="getopenMemberImport" :documentId="importedDocId"
          :nullDataInitializer="false" @modalHandler="modalHandler" />
        <MemberListTable :id="clubId"
          v-if="(memberList.length && fetchController == 'available') || isSearching?.length || anyFilterExistInMembers" />
        <MemberImport :payloadListTagAssign="getClubDetails" :nullDataInitializer="true"
          v-if="fetchController == 'notavaiable' && !anyFilterExistInMembers" />
        <MemberUploadSnackbar :clubId="clubId" v-if="modalVisibleForLog"></MemberUploadSnackbar>
        <AddIndividualMemberModal :payload="getClubDetails" v-if="addIndividulMemberModalVisible">
        </AddIndividualMemberModal>
      </div>
    </div>
  </div>
</div>
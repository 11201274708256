<div>
  <AppSnackBar
    :message="notifyMessage"
    :notificationType="notifyType"
    v-if="isNotify"
  ></AppSnackBar>
  <k-dialog
    :class="'modal-md-3'"
    :title="'Import Members'"
    @close="cancelModal"
  >
    <app-loader v-if="isImportLoading"></app-loader>
    <div class="border-normal-1 radius-8 mb-16">
      <div class="px-15 px-7-sm bg-gray-4 radius-8">
        <div class="row mb-0 p-12">
          <div class="col-6 d-flex align-items-center ps-1">
            <span class="pe-1 d-block font-13 font-w-500 text-color-4"
              >System Columns</span
            >
            <!-- <kbutton :fill-mode="'flat'" :class="'p-0'">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.39043 13.512C8.19027 13.7622 7.80973 13.7622 7.60957 13.512L4.64988 9.81235C4.38797 9.48497 4.62106 9 5.04031 9H10.9597C11.3789 9 11.612 9.48497 11.3501 9.81235L8.39043 13.512Z" fill="#A1A9B8"/>
                <path d="M8.39043 2.48804C8.19027 2.23784 7.80973 2.23784 7.60957 2.48804L4.64988 6.18765C4.38797 6.51503 4.62106 7 5.04031 7H10.9597C11.3789 7 11.612 6.51503 11.3501 6.18765L8.39043 2.48804Z" fill="#A1A9B8"/>
              </svg>                
            </kbutton> -->
            <!-- <Tooltip
              :open-delay="100"
              :position="'top'"
              :anchor-element="'target'"
              :className="'import-member-tooltip'"
            >
              <div :style="{'text-align': 'center'}">
                <span
                  class="k-icon k-i-information"
                  title="The system columns are general information of a member required to be registered on system."
                ></span>
              </div>
            </Tooltip> -->
          </div>
          <div class="col-6 d-flex align-items-center">
            <span class="pe-1 d-block font-13 font-w-500 text-color-4"
              >Columns in your files</span
            >
            <!-- <kbutton :fill-mode="'flat'" :class="'p-0'">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.39043 13.512C8.19027 13.7622 7.80973 13.7622 7.60957 13.512L4.64988 9.81235C4.38797 9.48497 4.62106 9 5.04031 9H10.9597C11.3789 9 11.612 9.48497 11.3501 9.81235L8.39043 13.512Z" fill="#A1A9B8"/>
                <path d="M8.39043 2.48804C8.19027 2.23784 7.80973 2.23784 7.60957 2.48804L4.64988 6.18765C4.38797 6.51503 4.62106 7 5.04031 7H10.9597C11.3789 7 11.612 6.51503 11.3501 6.18765L8.39043 2.48804Z" fill="#A1A9B8"/>
              </svg>                
            </kbutton> -->
            <!-- <Tooltip
              :open-delay="100"
              :position="'top'"
              :anchor-element="'target'"
              :className="'import-member-tooltip'"
            >
              <div :style="{'text-align': 'center'}">
                <span
                  class="k-icon k-i-information"
                  title="Select the columns from your uploaded file in order to map data to system columns."
                ></span>
              </div>
            </Tooltip> -->
          </div>
        </div>
      </div>

      <div class="px-15 radius-8">
        <div
          v-for="(item, index) in mapConfig"
          :key="index"
          class="row text-start border-top-normal-1 py-12 px-16"
        >
          <div class="mb-0 col-6 ps-0 align-self-center">
            <label class="font-14 font-w-500 mb-0">{{item.displayName}}</label>
          </div>
          <div class="col-6 pe-0">
            <div class="form-field">
              <dropdownlist
                @open="onOpen($event)"
                @close="onClose"
                :class="'mb--14'"
                :data-items="columns"
                :filterable="true"
                filter=""
                @filterchange="filterChange"
                :value="mapConfig[key]"
                @change="handleMap(index, $event)"
                :default-item="'Select Column'"
              >
              </dropdownlist>
              <div class="error-msg mt-3 ms-1 mb-0">{{item.errorMessage}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-normal-1 radius-8 mb-16">
      <div class="text-start">
        <div class="p-12 bg-gray-4 radius-8">
          <label
            class="m-0 font-13 font-w-500 text-color-2"
            v-if="payload?.enablePass || payload?.enableVoucher || payload?.enableCampaign"
            >Include Imported Members In</label
          >
        </div>

        <div class="px-15">
          <div
            v-if="payload?.enablePass"
            class="row k-input-mb-0 border-top-normal-1 py-16"
          >
            <div class="col-md-6 col-sm-12">
              <checkbox
                :value="this.digitalPassCheckBoxStatus"
                @change="this.handleDigitalPassCheckBoxStatus"
              >
                <label :class="'font-14 font-w-600 mb-0 ms-2'"
                  >Digital Pass</label
                >
              </checkbox>
              <label
                class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                v-if="this.digitalPassCheckBoxStatus"
                >Assign to Digital Pass list</label
              >
            </div>
            <div
              class="col-md-6 col-sm-12"
              v-if="this.digitalPassCheckBoxStatus"
            >
              <div class="dropdown-tree-wrap">
                <dropdowntree
                  @change="digitalPassListonChange"
                  :data-source="this.localDataSourceForDigitalPass"
                  :checkboxes="true"
                  :autoClose="false"
                  :placeholder="'Select List'"
                  style="width: 100%"
                >
                </dropdowntree>
                <span class="dropdown-item-count"
                  >{{digitalPassListIds.length}}</span
                >
              </div>
            </div>
          </div>
          <div
            v-if="payload?.enableVoucher"
            class="row k-input-mb-0 border-top-normal-1 py-16"
          >
            <div class="col-md-6 col-sm-12">
              <checkbox
                :value="this.voucherCheckBoxStatus"
                @change="this.handleVoucherCheckBoxStatus"
              >
                <label :class="'font-14 font-w-600 mb-0 ms-2'">Voucher</label>
              </checkbox>
              <label
                class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                v-if="this.voucherCheckBoxStatus"
                >Assign to Voucher list</label
              >
            </div>
            <div class="col-md-6 col-sm-12" v-if="this.voucherCheckBoxStatus">
              <div class="dropdown-tree-wrap">
                <dropdowntree
                  @change="voucherListonChange"
                  :data-source="this.localDataSourceForVoucher"
                  :checkboxes="true"
                  :autoClose="false"
                  :placeholder="'Select List'"
                  style="width: 100%"
                >
                </dropdowntree>
                <span class="dropdown-item-count"
                  >{{voucherCampaignListIds.length}}</span
                >
              </div>
            </div>
          </div>

          <div
            v-if="payload?.enableCampaign"
            class="row k-input-mb-0 border-top-normal-1 py-16"
          >
            <div class="col-md-6 col-sm-12">
              <checkbox
                :value="emailCampaignCheckBoxStatus"
                @change="handleEmailCampaignCheckBoxStatus"
              >
                <label :class="'font-14 font-w-600 mb-0 ms-2'"
                  >Email Campaign</label
                >
              </checkbox>
              <label
                class="font-13 font-w-500 text-color-2 mb-0 ml-22 d-block mt-1"
                v-if="emailCampaignCheckBoxStatus"
                >Assign to Campaign List</label
              >
            </div>
            <div class="col-md-6 col-sm-12" v-if="emailCampaignCheckBoxStatus">
              <div class="dropdown-tree-wrap">
                <dropdowntree
                  @change="emailCampaignListonChange"
                  :data-source="localDataSourceForEmailCampaign"
                  :noDataTemplate="'No lists found.'"
                  :checkboxes="true"
                  :autoClose="false"
                  :placeholder="'Select List'"
                  style="width: 100%"
                >
                </dropdowntree>
                <span class="dropdown-item-count"
                  >{{emailCampaignListIds.length}}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-3 mt-2">
      <checkbox
        :class="'font-14 font-w-500 cursor-pointer'"
        :value="subscriberStatusCheckedOrNot"
        @change="handleSubscriberStatusCheckBoxStatus"
        :label="'I confirm that my new member adheres to these conditions:'"
      />
      <ul class="pl-38 list-style-bullet gap-18 mt-3 text-start font-14">
        <li>
          My contact have granted me explicit consent to send emails within the
          past two years or have been asked to do so.
        </li>
        <li>These contact were not obtained from a third party source.</li>
        <li>These contact were not bought or leased.</li>
        <li>
          We reserve the right to halt or terminate email compaigns sent to
          contact.
        </li>
      </ul>
    </div>
    <dialog-actions-bar :class="'justify-content-end border-0'">
      <app-loader v-if="loading" class="form-spinner"></app-loader>
      <kbutton
        @click="cancelModal"
        :class="'flex-none border-normal-1 text-color'"
        :theme-color="'primary'"
        :fill-mode="'outline'"
        >Cancel</kbutton
      >
      <kbutton :theme-color="'primary'" @click="saveData" :class="'flex-none'"
        >Finalize Import & Upload Members</kbutton
      >
    </dialog-actions-bar>
  </k-dialog>
</div>

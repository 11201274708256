<div>
  <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
  <app-loader v-if="isColumnLoading"></app-loader>
  <Grid v-if="memberColumns.length > 0"
    :class="'first-col-checkbox-center members-table-wrap table-design-2 mb-60 th-td-checkbox-style-2'" ref="grid"
    :data-items="membersData.items" :skip="skip" :take="take" :total="total" :pageable="gridPageable" :selectable="true"
    :selected-field="selectedField" :columns="columns" @selectionchange="onSelectionChange"
    @datastatechange="dataStateChange" @headerselectionchange="onHeaderSelectionChange" :sortable="true" :sort="sort"
    @sortchange="sortChangeHandler">
    <toolbar>
      <div class="search-box-wrap width-220 w-100-vsm me-0 mr-0-vsm">
        <span class="k-icon k-i-zoom"></span>
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input :class="'mb-0 w-100'" placeholder="Search" :value="searchWord" @input="onSearch"></k-input>
        </span>
      </div>

      <div class="ms-auto w-100-vsm flex-vsm-wrap gap-3">
        <div class="d-flex flex-wrap gap-2 w-100-vsm">
          <span class="w-100-vsm mb-2 mb-sm-0">
            <DropDownButton :disabled="!hasEditAccess()" v-if="selectedItems.length > 0 " :class="'w-100-vsm'"
              :button-class="'border-normal-1 text-color d-flex flex-row-reverse bg-white hvr-bg-primary'"
              :popup-settings="popupSettings" :items="actionDropDown[selectedUserStatus]" :text="'Action'"
              :icon="'chevron-down'" :theme-color="'primary'" :fill-mode="'outline'" @itemclick="onActionClick" />
          </span>

          <span class="w-100-vsm">
            <kbutton :disabled="!hasDeleteAccess()" v-if="selectedItems.length > 0"
              :class="'w-100-vsm border-normal-1 text-color-red-2 bg-white hvr-bg-red-2 hvr-border-red-2 mt-1px'"
              :icon="'delete'" :theme-color="'primary'" :fill-mode="'outline'" @click="deleteMember">Delete
            </kbutton>
          </span>
        </div>
        <div v-if="!selectedItems?.length" class="d-flex flex-wrap gap-2 w-100-vsm">
          <div class="w-100-vsm mb-9">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm">Filter by Status</label>
              <dropdownlist :class="'w-100-vsm'" :style="{ width: '140px' }" :data-items="statusList"
                :text-field="'text'" :data-item-key="'id'" :value="selectedStatus"
                @change="handleValueChange($event.value, 'selectedStatus')">
              </dropdownlist>
            </span>
          </div>
          <div class="position-relative">
            <kbutton @click="memberFilterHandler" ref="buildFromScratchRef" :fill-mode="'outline'"
              :class="'h-38 w-38 border-normal-1 text-color bg-white hvr-bg-primary'" :theme-color="'primary'"
              :icon="'table-cell-properties'"></kbutton>
            <Popup :anchor="'buildFromScratchRef'" :show="memberFilterPopupVisibility"
              :popup-class="'member-filter-popup column-filter-popup w-219 radius-8'">
              <div v-click-outside="() => this.memberFilterPopupVisibility = false"
                class="p-16 d-flex flex-wrap flex-column gap-3">
                <template v-for="(item,index) in allColumns" :key="index">
                  <checkbox :class="'font-16 font-w-500 cursor-pointer checkbox-style-2'" :label="item.title"
                    :value="item.isChecked" @change="onColumnSelectionChange($event.value, index)" />
                </template>
              </div>
            </Popup>
          </div>
        </div>

      </div>
    </toolbar>
    <template v-slot:nameCell="{ props }">
      <td>
        <span @click="routeToMemberDetails(props.dataItem)"
          class="font-14 font-w-500 color-blue cursor-pointer">{{props.dataItem.name}}</span>
      </td>
    </template>

    <template v-slot:subscriberStatusCell="{props}">
      <td>
        <div class="d-flex justify-content-between">
          <span v-if="props.dataItem.subscriberStatus==0"
            class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8">Non-subscribed</span>
          <span v-if="props.dataItem.subscriberStatus==1"
            class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live">Subscribed</span>
          <span v-if="props.dataItem.subscriberStatus==2"
            class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 draft">Unsubscribed</span>
          <span v-if="props.dataItem.subscriberStatus==3"
            class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 paused">Cleaned</span>
        </div>
      </td>
    </template>
    <template v-for="(item,index) in dateCells" :key="index" v-slot:[item.cell]="{ props }">
      <td>
        <span> {{getDateLocal(props.dataItem[item.field])}} </span>
      </td>
    </template>
  </Grid>
  <RemoveMemberModal @completeDelete="completeDelete" :selectedItems="selectedItems" v-if="modalVisible" />
  <SetStatusActionModal :message="actionWarningMessage" :onConfirm="onConfirmActionHandler" :actionType="actionType"
    v-if="actionmodalVisible" />
</div>
<modal
  :title="'Remove Member'"
  :isLoading="deleteMemberLoading"
  :handler="modalVisible"
  :cssClass="'c-modal-small'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div>
      <p class="font-16 m-0 py-2 text-center">
        <!-- Are you sure you want to remove this Member from the {{isClub()}}? -->
        Do you want to delete the selected {{ selectedItems.length > 1 ? 'members' : 'member' }}? 
        This action cannot be undone and will permanently remove all selected 
        {{ selectedItems.length > 1 ? 'members' : 'member' }} from the system.
      </p>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1 w-50-p">
        <kbutton @click="cancelModal" :class="'w-100'">Cancel</kbutton>
      </div>
      <div class="ms-1 w-50-p">
        <kbutton :theme-color="'primary'" @click="deleteClub" :class="'w-100'"
          >Remove</kbutton
        >
      </div>
    </div>
  </template>
</modal>

<template src="./MemberMappingModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels";
import AppLoader from "../../../common/AppLoader/AppLoader.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import MemberUploadSnackbar from "../memberUploadSnackbar/MemberUploadSnackbar";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import loader from "../../../common/AppLoader/AppLoader";
import { POST } from "@/service";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { getLists } from "@/services/clubDetails/list.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";

export default {
  components: {
    Tooltip,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    error: Error,
    "app-loader": AppLoader,
    dropdownlist: DropDownList,
    MemberUploadSnackbar,
    AppSnackBar,
    loader,
    dropdowntree: DropDownTree,
    checkbox: Checkbox,
  },
  props: {
    documentId: "",
    dialogue: {
      type: Boolean,
      default: true,
    },
    payload: {
      type: Object,
    },
  },
  data: function () {
    return {
      digitalPassCheckBoxStatus: false,
      voucherCheckBoxStatus: false,
      localDataSourceForDigitalPass: [],
      digitalPassListIds: [],
      localDataSourceForVoucher: [],
      voucherCampaignListIds: [],
      emailCampaignListIds: [],
      localDataSourceForEmailCampaign: [],
      mapConfig: [
        {
          column: "FirstName",
          mapFrom: "",
          displayName: "First Name*",
          errorMessage: "",
        },
        {
          column: "LastName",
          mapFrom: "",
          displayName: "Last Name*",
          errorMessage: "",
        },
        {
          column: "Email",
          mapFrom: "",
          displayName: "Email*",
          errorMessage: "",
        },
        {
          column: "Mobile",
          mapFrom: "",
          displayName: "Phone",
          errorMessage: "",
        },
        {
          column: "Gender",
          mapFrom: "",
          displayName: "Gender",
          errorMessage: "",
        },
        {
          column: "JoiningDate",
          mapFrom: "",
          displayName: "Joining Date",
          errorMessage: "",
        },
        {
          column: "TimeZone",
          mapFrom: "",
          displayName: "Time Zone",
          errorMessage: "",
        },
      ],
      timeZoneValue: "",
      clubId: this.$route.params.id,
      memberImportModalVisible: false,
      searchData: "",
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      isImportLoading: false,
      emailCampaignCheckBoxStatus: false,
      preferencesCheckBoxStatus: false,

      subscriberStatusCheckedOrNot: false,
    };
  },
  computed: {
    columns() {
      return this.$store.state.members.columnList;
    },
    modalVisible() {
      return this.$store.state.members.loader;
    },
    isImported() {
      return this.$store.state.members.isImported;
    },
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    club() {
      return this.$store.state.club.club;
    },
  },
  watch: {
    timeZonesList(newVal) {
      const timeZone = this.club.timeZoneId;

      this.timeZoneValue = this.timeZonesList.find((item) => {
        return item.id === timeZone;
      });
    },
  },
  mounted() {
    this.getColumns(this.documentId);
    this.getDigitalPassList();
    this.getVouchersList();
    this.getEmailCampaignList();
    this.$store.dispatch("getTimeZonesList", 999);
  },
  methods: {
    handlePreferencesCheckBoxStatus(event) {
      this.preferencesCheckBoxStatus = event.value;
    },
    handleSubscriberStatusCheckBoxStatus(event) {
      this.subscriberStatusCheckedOrNot = event.value;
    },
    digitalPassListonChange(event) {
      this.digitalPassListIds = event.sender._values;
    },
    voucherListonChange(event) {
      this.voucherCampaignListIds = event.sender._values;
    },
    emailCampaignListonChange(event) {
      this.emailCampaignListIds = event.sender._values;
    },
    handleDigitalPassCheckBoxStatus(event) {
      this.digitalPassCheckBoxStatus = event.value;
    },
    handleVoucherCheckBoxStatus(event) {
      this.voucherCheckBoxStatus = event.value;
    },
    handleEmailCampaignCheckBoxStatus(event) {
      this.emailCampaignCheckBoxStatus = event.value;
    },
    async getDigitalPassList() {
      const payload = {
        clubId: this.$route.params.id,
        entityType: 1,
        limit: 20,
      };

      try {
        const result = await getLists(payload);
        if (result?.data?.data?.items?.length > 0) {
          result?.data?.data?.items.forEach((list) => {
            this.localDataSourceForDigitalPass.push({
              text: list.name,
              value: list.id,
            });
          });
        }
      } catch (error) {}
    },
    async getVouchersList() {
      const payload = {
        clubId: this.$route.params.id,
        entityType: 3,
        limit: 20,
      };
      try {
        const result = await getLists(payload);
        if (result?.data?.data?.items?.length > 0) {
          result?.data?.data?.items.forEach((list) => {
            this.localDataSourceForVoucher.push({
              text: list.name,
              value: list.id,
            });
          });
        }
      } catch (error) {
        // ;
      }
    },
    async getEmailCampaignList() {
      const payload = {
        clubId: this.$route.params.id,
        entityType: 2,
        limit: 20,
      };
      try {
        const result = await getLists(payload);
        if (result?.data?.data?.items?.length > 0) {
          result?.data?.data?.items.forEach((list) => {
            this.localDataSourceForEmailCampaign.push({
              text: list.name,
              value: list.id,
            });
          });
        }
      } catch (error) {
        // ;
      }
    },
    onOpen(e) {
      this.searchData = "";
      this.getColumns(this.documentId);
    },
    onClose() {
      this.searchData = "";
    },
    cancelModal() {
      this.$store.dispatch("importCloseModal");
      this.$emit("closeMemberMapping", true);
    },
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.members.columnModifier;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_COLUMNS", this.searchData);
    },
    filterData(filter) {
      const data = this.columns.slice();
      return filterBy(data, filter);
    },
    getColumns(id) {
      let payload = {
        clubId: this.$route.params.id,
        documentId: id,
      };
      this.$store.dispatch("getColumns", payload);
    },
    handleMap(index, e) {
      this.mapConfig[index].mapFrom = e.value;
      if (this.mapConfig[0].mapFrom != "") {
        this.mapConfig[0].errorMessage = "";
      }
      if (this.mapConfig[1].mapFrom != "") {
        this.mapConfig[1].errorMessage = "";
      }
      if (this.mapConfig[2].mapFrom != "") {
        this.mapConfig[2].errorMessage = "";
      }
      this.$store.commit(
        "SET_COLUMNS",
        this.$store.state.members.columnModifier
      );
    },
    saveData() {
      this.mapConfig[0].errorMessage = "";
      this.mapConfig[1].errorMessage = "";
      this.mapConfig[2].errorMessage = "";

      if (
        this.mapConfig[0].mapFrom == "" ||
        this.mapConfig[0].mapFrom == "Select Column"
      ) {
        this.mapConfig[0].errorMessage = "First Name is a required field.";
      }
      if (
        this.mapConfig[1].mapFrom == "" ||
        this.mapConfig[1].mapFrom == "Select Column"
      ) {
        this.mapConfig[1].errorMessage = "Last Name is a required field.";
      }
      if (
        this.mapConfig[2].mapFrom == "" ||
        this.mapConfig[2].mapFrom == "Select Column"
      ) {
        this.mapConfig[2].errorMessage = "Email is a required field.";
      }

      // if(!this.isImported){
      //   this.showNotification("Something went wrong with uploaded file, please upload a valid file.", "error");
      //   return;
      // }

      var error = this.mapConfig.filter((x) => x.errorMessage);

      this.mapConfig.forEach((element) => {
        if (element.mapFrom == "Select Column") {
          element.mapFrom = "";
        }
      });

      if (error.length > 0) {
        return;
      }

      var mapConPayload = this.mapConfig.filter((x) => x.mapFrom);

      let payload = {
        clubId: +this.clubId,
        documentId: this.documentId,
        mappingConfig: mapConPayload,
        timeZone: this.timeZoneValue?.name,
        subscriberStatus: this.subscriberStatusCheckedOrNot ? 1 : 0,
      };

      if (this.digitalPassCheckBoxStatus) {
        payload.assignToDigitalPassLists = true;
        payload.digitalPassListIds = this.digitalPassListIds;
      }

      if (this.voucherCheckBoxStatus) {
        payload.assignToVoucherCampaignLists = true;
        payload.voucherCampaignListIds = this.voucherCampaignListIds;
      }

      if (this.emailCampaignCheckBoxStatus) {
        payload.assignToEmailCampaignLists = true;
        payload.emailCampaignListIds = this.emailCampaignListIds;
      }

      this.$store.commit("SET_IMPORT_RESPONSE", 0);
      // this.$store.commit("LOADING_STATUS", true);
      this.isImportLoading = true;
      POST(`api/v1/member/${payload.clubId}/members/import/`, payload)
        .then((response) => {
          this.$store.commit("SET_IMPORT_RESPONSE", response);
          this.$store.commit("LOADING_STATUS", false);
          this.$store.commit("SET_IMPORT_LOADER", true);
          // commit("SET_IMPORT_RESPONSE", response);
          // commit("SET_IS_IMPORTED", true);
          this.isImportLoading = false;

          // this.$store.dispatch("importMembers", payload);
          this.$store.dispatch("importCloseModal");
          this.$emit("parentModalhandler", false);
          this.$emit("closeAllModals");

          let data = {
            clubId: payload.clubId,
            limit: 100,
          };
          this.$store.dispatch("getMembers", data);
        })
        .catch((err) => {
          // commit("SET_IS_IMPORTED", false);
          // this.showNotification(
          //   "Something went wrong with uploaded file, please upload a valid file.",
          //   "error"
          // );

          var model = new SnackBarModel();
          model.type = "error";
          model.Class = "status-error";
          model.Message = `Something went wrong with uploaded file, please upload a valid file.`;

          this.$store.commit("SET_ERROR_STATUS", model);
          this.$store.commit("SET_ERROR_MESSAGE", true);
          setTimeout(() => {
            this.$store.commit("SET_ERROR_MESSAGE", false);
          }, 40000);
          this.$store.commit("LOADING_STATUS", false);
          this.$emit("parentModalhandler", false);
          this.$emit("closeAllModals");
          this.$store.dispatch("importCloseModal");
          this.isImportLoading = false;
          // this.$store.commit("SET_CONTROL_STATUS", model);
          // this.$store.commit("SET_SHOW_MESSAGE", true);
          // setTimeout(() => {
          //   this.showNotification(
          //     "Something went wrong with uploaded file, please upload a valid file.",
          //     "error"
          //   );
          // }, 2000);
        });

      // if (this.mapConfig[3].mapFrom == "Select Column") {
      //   this.mapConfig[3].mapFrom = "";
      // }
      // if (this.mapConfig[4].mapFrom == "Select Column") {
      //   this.mapConfig[4].mapFrom = "";
      // }

      // let payload = {
      //   clubId: +this.clubId,
      //   documentId: this.documentId,
      //   mappingConfig: this.mapConfig,
      // };
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = "SSSSSSS"),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
  },
};
</script>
